.fullHeight{
  height: 100vh
}

ul{
  list-style: none;
}

header {
	display: flex;
	justify-content: flex-end;
}

.liquidFillGaugeText { font-family: Helvetica; font-weight: bold; }

.pOff{
  padding:0 !important
}

.top-clearance{
  top:3% !important;
}

.headerFont{
 font-family:"Century Gothic"
}

.pOff-tb{
  padding-top:0% !important;
  padding-bottom: 0% !important;
}

.bodyFont{
  font-family:"Century Gothic"
}

.transBg{
  background-color: transparent !important;
}

.leftFloat{ 
  float:left !important;
}

.slider-news{
  position: absolute;
  z-index: 31;
  top:65.5%;
  background-color: rgba(0,0,0, 0.5);
}
.resourceCardHeight{
  height:100px
}
.noDecoration{
  text-decoration: none;
  border: transparent;
  color:black
}

.noDecoration:hover{
  color: orange !important;
}

.contactFormDesign{
   border-radius:10px;
   padding-top: 10%;
   padding-bottom:10%
}

.resourceBtnPosition{
  position: relative;
  top: 28%;
}

.whiteBackground{
  background: white;
}

.monsterText{
  font-size: 10rem;
}

.businessDescriptionPadding{
  padding: 10% 3%;
}

.paddingTBRL{

  padding: 4%
}

.plOff{
  padding-left:0
}

.prOff{
  padding-right:0
}

.trans_bg{
  background-color: transparent;
}

.contactSideBar{
 background-image: ;
}

.color_bg{
  background-color: rgba(288,288,288,0.5);
}

.mTOff{
  margin-top: 0%
}

/* Burger icon */
#menu-btn {
	position: relative;
  left: 1% !important;
	z-index:2;
	overflow: hidden;
}

#menu-btn span {
	width: 30px;
	height: 2px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	transition: all 0.5s;
}

#menu-btn span:nth-of-type(2) {
	top: calc(50% - 5px);
}
#menu-btn span:nth-of-type(3) {
	top: calc(50% + 5px);
}

/* Changing the hamburger icon when the menu is open */
#menu-btn.active span:nth-of-type(1) {
  display: none;
}
#menu-btn.active span:nth-of-type(2) {
  top: 50%;
  transform: translate(-50%, 0%) rotate(45deg);  
}
#menu-btn.active span:nth-of-type(3) {
  top: 50%;
  transform: translate(-50%, 0%) rotate(-45deg); 
}

/* Menu that will appear */
#menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 15px;
  background: #FFEFBA;
	transform: translateX(-100%);
	transition: transform 0.5s; 
}

#menu.active {
	transform: translateX(0);
}

#menu li {
	list-style-type: none;
}

.logoMargin{
   margin-left: 1%
}

.whiteText{ 
  color: white !important;
}

.blackText{
  color: black
}

.resourceText:hover{
  color:#ff6600;
  transition: 1s ease-in-out
}

.top-text-clearance{
  margin-top: 11%
}

.fullWidth{
  width:100% !important
}

.halfWidth{
  width: 50%
}

.borderControl{
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color:transparent !important;
  border-bottom-color: transparent !important;
  border: 0px;

}

.greyShadow{
  box-shadow:  #8f8f8f 2px 2px 5px;
}

.divider{
  border:4px solid #ff6600 !important;
  border-right-color:#ff6600;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}

.dividerB{
  border:4px solid #ff6600 !important;
  border-left-color:#ff6600;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.borderBottomOff{
  border-bottom-color:transparent
}

.mOff{
  margin:0px
}

.heavyFont{
  font-weight: 900;
  font-size: 3.0rem
}

.landingContentArea{
  margin-left: 2%;
}

.cardDesign{
  background-color:transparent;
  border: 1.5px solid white
}

.cardDesignMenu{
  background-color:transparent;

}

.cardBodyDesign{
  opacity:0.85;
  background-color: white;
}

.standard-banner-padding{
  padding: 14% 10%
}

.standard-banner-padding-alt{
  padding: 10% 10%
}

.standard-banner-padding-vertical{
  padding: 3% 28%
}

.standard-banner-padding-top-vertical{
  padding-top: 21%
} 


.standard-padding-top{
  padding-top: 3%
}

.standard-padding-rl-2{
  padding: 0% 15%
}



.standard-padding-rl-3{
  padding-right: 16%;
  padding-left: 16%;
}


.greyText{
  color:#b0b0b0
}

.greyText2{
  color:#474545
}

.mediaThrillPosition{
  margin-top: -16%;
}

.slick-prev{
 background-color: rgba(255, 102, 0, 0.9) !important;
 color: white !important;
  left: 15px !important;
}

.slick-next{
  background-color: rgba(255, 102, 0, 0.9) !important;
  color: white !important;
  right:20px !important;
 }

 #homepageslidedsk{
  height: 60vh !important;
 }

 .slick-dots{
  position:relative !important;
  color:rgba(255, 102, 0, 0.9) !important
 }

 .commentsBoxHeight{
  height: 200px;
 }

.greyBackground{
  background:linear-gradient(0deg, rgba(143, 143, 143, 1), rgba(0, 0, 0, 0.6))
}

.blueBackground{
  background:linear-gradient(0deg, rgba(29, 124, 196, 0.9), rgba(0, 15, 99, 0.6))
}

.orangeBackground{
  background:linear-gradient(0deg, rgba(255, 102, 0, 0.9), rgba(242, 140, 71, 0.6))
}

.servicesCard{
  height: 475px
}

#about{
  position: relative;
    top: 1%;
    background-color: white;
}

.midSizeText{
  font-size: 1.5em;
}


.nav-link:hover {
  color:#ff6600 !important;
}

.blackText{
  color: black 
}

#citizen, #table{
  margin-top:-10%
}

#table{
  margin-bottom:10%
}


.smallText{
  font-size: 0.5rem
}

.leadwayOrange{
  background-color: #ff6600 !important;
}

.standard-padding-tb{
  padding: 4% 0%
}

.standard-padding-tb-unequal{
  padding-top: 4%;
  padding-bottom: 1%; 
  padding-right:0%;
  padding-left:0%;
}


.standard-padding-tb-3{
  padding: 12% 0%
}

.vertical-image-height{
  height: 400px
}

.vertical-text-box-height{
  height: 130px
}

.solutionsText{
  display: none;
}

.vertical-padding-bottom{
  padding-bottom: 20%
}

.vertical-text-positon{
  position:relative;
  top: 12%
}


.standard-padding-tb-2{
  padding-top: 2%;
  padding-bottom: 3%
}

.standard-padding-bottom{
  padding-bottom: 6%
}


.standard-padding-tb-submenu{
  padding: 4% 0%
}

.standard-padding-rl{
  padding: 0% 4%
}

.rightFloat{
  float:right 
}


.standard-padding-rl-tb{
  padding: 4%
}

.standard-padding-rl-tb-resources{
  padding: 10%
}

.standard-padding-rl-tb-min{
  padding: 4% 2%;
}


.standard-padding-rl-tb-citizen{
  padding: 8% 4%
}


.standard-padding-rl-tb-process{
  padding: 4%
}

.standard-padding-tb-nav{
  padding:2%
}

.orangeText{
  color:#ff6600
}

.whiteBackgroundP


.values:hover{
   box-shadow: #8f8f8f 0px 4px 0px 0px !important 
}

.solutionNamePad{
  padding: 0 20%;
  font-size: 1.55rem;
}

.solutionNameSize{
  font-size: 1.3rem !important;
}

#contactPage{
  margin-top: -37.4%;
    margin-left: 2%;
}

.nav-menu-width{
  width:100%
}


.glassBackground{
  background: rgba(244, 244, 244, 0.8) 
}


#flex1{
  display: flex; 
  justify-content: space-around; 
}

.btnDesign{
  border-color:#ff6600;
  background-color: white !important;
  color:#ff6600
}

.btnDesignAcc{
  border-color:#ff6600;
  background-color: white !important;
  color:#ff6600
}

.btnDesign2{

  border-color:#ff6600;
  background-color: white!important;
  color:#ff6600
}

.btnDesign2:hover{
  border-color:transparent;
  background-color: #ff6600 !important;
  transition: 1s ease-in-out;
  color: white
}

.btnDesignAlt{
  border:1.5px solid #ff6600;
  background-color: white!important;
  color: #ff6600

}

.btnDesignAlt2{
   border: #ff6600 !important;
   background : white !important;
   color:#ff6600
}

.btnDesignAlt3{
  border: #ff6600 !important;
  background : transparent !important;
  color: white
}


.btnDesignAlt:hover{
  border-color:transparent;
  background-color:#ff6600 !important;
  transition: 1s ease-in-out
}

.btnDesign:hover{
  padding: 3px 15px;
  border-color:transparent;
  background-color: #ff6600 !important;
  transition: 1s ease-in-out
}

.barStyle{
  width: 30%;
  border: 3px solid #ff6600 !important;
  opacity: 1;
}

.barStyleHeading{
  width: 30%;
  border: 3px solid #ff6600 !important;
  opacity: 1;
}

.blackBg{
  background-color: black;
}

.barStyleMenu{
  width: 80%;
  border: 1px solid #ff6600 !important;
  opacity: 1;
}

.barStyleMenuAlt{
  width: 34%;
  border: 1px solid #ff6600 !important;
  opacity: 1;
}

.barStyleGP{
  width: 15%;
  border: 3px solid #ff6600 !important;
  opacity: 1;
}

.barStyleGPLong{
  width: 15%;
  border: 3px solid #ff6600 !important;
  opacity: 1;
}

.orangeShadow{
  box-shadow: #8f8f8f 0px 0px 15px 0px;
}

.menu{
  position: fixed;
  z-index: 3000;
  width: 95%;
  margin:auto;
  right:3%;
  top: 3%;
  display: none;
}

.accordion{ 
  --bs-accordion-btn-focus-border-color: #e2a61c !important ;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(253 207 13 / 25%) !important;
  --bs-accordion-active-color: #ffc107 !important;
  --bs-accordion-active-bg: #000 !important;
}

button.accordion-button::after{ 
  background-color:#ffc107;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

button.accordion-button.active::after{ 
  color:#000 !important;
}


.menuFontSize{
  font-size: 1.5rem;
  color: black
}

.menuColumn{
  background-color: rgb(255, 255, 255);
  border: 5px solid rgb(255, 255, 255);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-color: transparent;
}

.menuSelectionColumn{
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-color: transparent;
}

.menuLogoPosition{ 
  position: relative;
  top: -13%;
}

.btnDesignAlt2:hover{
 color: white !important;
 background-color: #ff6600 !important;
}

 .menuHeadingClearance{
  margin-left: 12%
 }

 .menuHeadingClearance2{
  margin-left: 4%
 }

 .menuHeadingClearance3{
  margin-left: 4%
 }


.menuCloseFont{
  font-size: 2rem;
}

#menu-btn, #menuClose, .footerLink {
  cursor: pointer
}

.footerLink:hover{ 
  color: #ff6600 !important;

}

.menuLinkWrapper{
  width: 34%
}


.menuLinkWrapper:hover{
background-color: #ff6600;
color:white !important
}

.standard-padding-rl-tb-menu-inner {
  padding: 4% 8%;
}

.form-control-2 {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00095B;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-bottom: 2.5rem;
  appearance: none;
  border-radius:0px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


form {
justify-content: center;
display: block;
margin-top: 0em;
width: 40%;
}

#hiringProcess{
  background-color: #fff;
}

#hiring{
  margin-top: -50%;
  margin-left: 2%;
}

#hiringGlass{
 background-color: rgb(240, 168, 61, 0.7)
}

.marginGlass{
 margin-top: 13%;
}

.marginGlass2{
  margin-top: 3%;
 }

 .marginGlass3{
  margin-top: 4%;
 }

 .contactFormHeight{
  height: 100vh
 }

 .formArea{
  padding-top: 9%;
  padding-left: 13%;
  padding-right: 9%;
 }

.roleAlt{
  display:none
}

.nav-link.active{
  color:#ff6600 !important;
  border-bottom-color:#ff6600 !important;
}

.teamPicHeight{
  height: 380px
}

.teamDetailsDesign{
  
  margin-top: 68%;
  color:white !important;
}
.teamFirstNameDesign{
  font-size:0.8rem
}

.teamBoxMargin{
  margin-bottom: 8%
}

.mBOff{
  margin-bottom:0;
}

.footerBtn{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1.5px solid #ff6600;
}

.footerBtn:hover{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 3px solid grey;
  background-color: grey !important;
  transition: 1s ease-in-out
}

.footerForm{
  border-top-right-radius: 0px; 
  border-bottom-right-radius: 0px;
  border: 1.5px solid #ff6600 ;
}


.modal-dialog{
  max-width: 80%
}

.smallText{
  font-size:0.5rem
}

.profile{
 height: 380px
}

.smallText{
   font-size: 0.5rem 
}

.teamClearance{
  margin-top: -30%
}

.mobTrans{
  opacity: 1
  }


.text-box {
    position: relative;    
   
    padding: 5%;

}

.margin-top-vertical{
  margin-top: 5%
}

.teamHover:hover{
  color:black
}

.barStyleInsights {
  width: 30px;
  border: 1px solid #ff6600 !important;
  opacity: 1;
}

.insightDescription{ 
  font-size:10px
}

.insightHeading{ 
  font-size:1.1rem
}



@media(max-width:991px){ 
   
     .centerMobile{
         margin: auto;
     }

     .mediaThrill{
      height: 500px
     }

     .modal-dialog{
      max-width: 100% !important;
     }

     .btnDesign{
      border-color:transparent;
      background-color: grey !important;
      
    }

.standard-banner-padding-top-vertical {
  padding-top: 47%;
}

     .standard-banner-padding-vertical {
      padding: 3% 5%;
  }

     .mBMobile{
       margin-bottom: 4%
     }

     .logoSizeFooter{
      width: 40% !important;
     }

     .logoSize{
      width: 100% !important;
     }

     .menuHeadingClearance2{
       margin-left: 0%
     }

     .heavyFont{
      font-size:2rem !important
     }

     .top-text-clearance{
      margin-top: 26% !important
     }

     .appLogoSize{
      width:30% !important;
     }

     .top-text-clearance-2{
      margin-top: 10% !important
     }

     .resourceBoxMargin{
      margin-bottom: 2%
    }

    .midSizeText {
      font-size: 1em;
  }

  .teamDetailsDesign {
    margin-top: 77%;
}

form {
  width: initial;
  }
#hiring{
  margin-left: -4%;
}

#contactPage{
  margin-left: -4%;
}

.menuLinkWrapper {
  width: 84%;
}
.menu {

  top: -1%;
}

.mobTrans{
  opacity: 0.7
  }
  

}